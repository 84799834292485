import { useEffect, useRef, useState } from "react";
import './App.css';
import NewsItem from './NewsItem';

type NewsArticle = {
    author: string;
    publishedTimestampString: string;
    title: string;
    url: string;
}

type NewsSummary = {
    fullText: string;
    summaryText: string;
    title: string;
    url: string;
}

type HourlyNewsError = {
    errorMessage: string,
}

type NewsSummaryCollection = {
    newsArticles: NewsArticle[];
    newsSummaries: NewsSummary[];
    newsSummariesAudioLinks: string[];
    singleSummaryAudioLink: string,
}

type HourlyNewsSummaryCollection = {
    newsSummaryResponse: NewsSummaryCollection,
    rfc3339TimestampString: string,
}

function getNewsTimeString(rfc3339TimestampString: string): string {
    let date: Date = new Date(rfc3339TimestampString);
    let timePeriodString = date.getHours() >= 12 ? "pm" : "am"
    let hour = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
    return `${hour} ${timePeriodString} ${date.toLocaleString("en-us", { month: "long" })} ${monthDayNumberToOrdinalString(date.getDate())}, ${date.getFullYear()}`;
}

function monthDayNumberToOrdinalString(monthNumber: number): string {
    if (monthNumber > 3 && monthNumber < 21) {
        return monthNumber + "th";
    }
    switch (monthNumber % 10) {
        case 1: return monthNumber + "st";
        case 2: return monthNumber + "nd";
        case 3: return monthNumber + "rd";
        default: return monthNumber + "th";
    }
}

function snakeCaseToCamelCaseObject(input: object): object {
    let output = Object.fromEntries(Object.entries(input).map(item => [snakeCaseToCamelCase(item[0]), item[1] instanceof Object ? snakeCaseToCamelCaseObject(item[1]) : item[1]]));
    if (input instanceof Array) {
        output = Array.from(Object.entries(output).map(item => item[1]));
    }
    return output;
}

function snakeCaseToCamelCase(input: string): string {
    let output = input
        .split("_")
        .reduce(
            (res, word, i) =>
                i === 0
                    ? word.toLowerCase()
                    : `${res}${word.charAt(0).toUpperCase()}${word
                        .substring(1)
                        .toLowerCase()}`,
            ""
        );
    return output;
}

async function checkForFile(url: string): Promise<boolean> {
    let hourlyNewsSummaryResult = await fetch(url, { method: "GET", });
    return hourlyNewsSummaryResult.status === 200;
}

function App() {
    const apiUrl = process.env.REACT_APP_URL;
    const query = new URLSearchParams(window.location.search);
    let dateString = query.get("date_string");

    const [allAudioFilesExist, setAllAudioFilesExist] = useState<boolean>(false);
    const [hourlyNewsSummaryCollection, setHourlyNewsSummaryCollection] = useState<HourlyNewsSummaryCollection | null>(null);
    const [hourlyNewsError, setHourlyNewsError] = useState<HourlyNewsError | null>(null);
    let fileCheckIntervalIdRef = useRef<NodeJS.Timer | null>(null);

    // State-Ref mappings to avoid stale state problem in closures.
    let hourlyNewsSummaryCollectionRef = useRef<HourlyNewsSummaryCollection | null>(null);
    hourlyNewsSummaryCollectionRef.current = hourlyNewsSummaryCollection;
    let allAudioFilesExistRef = useRef(false);
    allAudioFilesExistRef.current = allAudioFilesExist;

    const checkAllAudioExists = async (): Promise<boolean> => {
        if (hourlyNewsSummaryCollectionRef.current === null || allAudioFilesExistRef.current) {
            return false;
        }
        let allAudioLinksExist = true;
        let summaryAudioLinks = hourlyNewsSummaryCollectionRef.current.newsSummaryResponse.newsSummariesAudioLinks;
        for (let index in summaryAudioLinks) {
            let curFileExists = await checkForFile(summaryAudioLinks[index]);
            allAudioLinksExist = allAudioLinksExist && curFileExists;
        }
        let fullSummaryAudioPresent = await checkForFile(hourlyNewsSummaryCollectionRef.current.newsSummaryResponse.singleSummaryAudioLink);
        return allAudioLinksExist && fullSummaryAudioPresent;
    };



    useEffect(() => {
        const getHourlyNewsSummary = async () => {
            let hourlyNewsSummaryEndpoint = apiUrl + "/getHourlyNewsSummary";
            if (dateString != null) {
                hourlyNewsSummaryEndpoint = apiUrl + `/getPreviousHourlyNewsSummary/${dateString}`;
            }
            let hourlyNewsSummaryResult = await fetch(
                hourlyNewsSummaryEndpoint,
                { method: "GET", });
            let jsonResultSnakeCase = await hourlyNewsSummaryResult.json();
            let jsonResult = snakeCaseToCamelCaseObject(jsonResultSnakeCase);
            if ("errorMessage" in jsonResult) {
                let hourlyNewsError: HourlyNewsError = jsonResult as HourlyNewsError;
                console.error("ERROR: Could not retrieve hourly news summary! Error message was: '%s'", hourlyNewsError.errorMessage);
                setHourlyNewsError(hourlyNewsError);
            } else {
                let hourlyNewsSummaryCollectionJsonResult: HourlyNewsSummaryCollection = (jsonResult as unknown) as HourlyNewsSummaryCollection;
                setHourlyNewsSummaryCollection(hourlyNewsSummaryCollectionJsonResult);
            }
        };
        getHourlyNewsSummary();
    }, [apiUrl, dateString]);

    console.log("allAudioFilesExist: '%s' fileCheckIntervalId ref: '%s'", allAudioFilesExist, fileCheckIntervalIdRef.current);
    if (!allAudioFilesExist && fileCheckIntervalIdRef.current == null) {
        let intervalId = setInterval(async () => {
            console.log("interval running with fileCheckIntervalId from ref: '%s'", fileCheckIntervalIdRef.current);
            let allAudioExists = await checkAllAudioExists();
            setAllAudioFilesExist(allAudioExists);
            if (
                fileCheckIntervalIdRef.current != null &&
                allAudioExists
            ) {
                clearInterval(fileCheckIntervalIdRef.current);
                fileCheckIntervalIdRef.current = null;
            }
        }, 30000);
        fileCheckIntervalIdRef.current = intervalId;
    }

    let fullSummary = <div id="fullNewsSummaryPlaceholder" />
    let newsItems = [<div id="newsItemsPlaceholder" />];
    if (hourlyNewsSummaryCollection !== null) {
        let hourlyNewsSummary = hourlyNewsSummaryCollection.newsSummaryResponse;
        newsItems = [];
        let fullSummaryTitle = "News from";
        fullSummary = (<div className="full-news-summary">
            <h6 className="full-news-summary-title">
                <p className="full-news-summary-title-paragraph">
                    {fullSummaryTitle}<br />
                    {getNewsTimeString(hourlyNewsSummaryCollection.rfc3339TimestampString)}
                </p>
            </h6>
            <audio className="full-news-summary-audio" controls preload="auto" src={hourlyNewsSummary.singleSummaryAudioLink}></audio>
        </div >);
        hourlyNewsSummary.newsSummariesAudioLinks.forEach(
            (value: string, index: number) => {
                let key = "news-item--" + index;
                newsItems.push(
                    <NewsItem
                        audioSummaryFileLocation={value}
                        dateString={hourlyNewsSummary.newsArticles[index].publishedTimestampString}
                        extraClassName={index % 2 === 0 ? "news-item-even" : "news-item-odd"}
                        key={key}
                        newsItemKey={key}
                        summary={hourlyNewsSummary.newsSummaries[index].summaryText}
                        text={hourlyNewsSummary.newsSummaries[index].fullText}
                        title={hourlyNewsSummary.newsArticles[index].title}
                        url={hourlyNewsSummary.newsArticles[index].url} />)
            });
    } else if (hourlyNewsError !== null) {
        newsItems = [<div id="newsItemError">{hourlyNewsError.errorMessage}</div>];
    }

    return (
        <div className="app">
            <header className="app-header">
                <h2 className="page-title">Audio News</h2>
                <div className="news-content">
                    {fullSummary}
                    {newsItems}
                </div>
            </header>
        </div >
    );
}

export default App;
