import React from 'react';

interface NewsItemProps {
    audioSummaryFileLocation: string,
    dateString: string,
    extraClassName: string,
    newsItemKey: string,
    summary: string,
    text: string,
    title: string,
    url: string,
}

const NewsItem: React.FC<NewsItemProps> = (props) => {
    return <div className={"news-item " + props.extraClassName} id={props.newsItemKey} key={props.newsItemKey}>
        <h6 className="news-item-title">{props.title}</h6>
        <a className="news-item-link" href={props.url}>{props.url}</a>
        <audio className="news-item-audio" controls preload="auto" src={props.audioSummaryFileLocation}></audio>
    </div>
}

export default NewsItem;
